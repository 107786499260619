<template>
  <div>
    <GameMessages
      :all-filled-up="allFilledUp"
      :has-wrong-values="hasWrongValues"
    />
    <table class="sudoku">
      <tbody>
      <tr v-for="(row, idx) in grid" :key="idx">
        <td
            v-for="(cell, idy) in row"
            :key="idy"
            :class="getCellClass(idx, idy)"
            @click="setSelected(grid[idx][idy], idx, idy)"
        >
          {{ grid[idx][idy].value }}
        </td>
      </tr>
      </tbody>
    </table>
    <TableValues
        v-bind:game="game"
        v-bind:game-store-obj="this.$store"
        v-bind:valid-values="validValues"
        v-bind:num-values-per-row="11"
    ></TableValues>
    <GameActions
        v-bind:game="game"
        v-bind:showed-actions="showedActions"></GameActions>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {getGridCellClass, handleKeyPress} from "@/config/stores/common";
import GameMessages from "@/components/games/GameMessages.vue";
import TableValues from "@/components/games/TableValues.vue";
import GameActions from "@/components/games/GameActions.vue";

export default {
  name: "Sudoku_9x9",
  components: {
    GameActions,
    TableValues,
    GameMessages,
  },
  props: {
    game: {
      type: String,
      default: "sudoku"
    },
    gameStore: {
      type: String,
      default: "sudokuStore"
    },
    period: {
      type: String,
      default: "",
    },
    gameId: {
      type: String,
      default: "",
    },
    showedActions: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    ...mapState("sudokuStore", {
      grid: (state) => state.grid,
      difficulty: (state) => state.difficulty,
      allFilledUp: (state) => state.allFilledUp,
      solutionAchieved: (state) => state.solutionAchieved,
      hasWrongValues: (state) => state.hasWrongValues,
      completed: (state) => state.completed,
    }),
    ...mapState({
      account: (state) => state.account,
    }),
    gameStoreObj() {
      return this.$store
    },
    validValues() {
      return ["1", "2", "3", "4", "5", "6", "7", "8", "9", ""];
    },
  },
  methods: {
    getCellClass(x, y) {
      return getGridCellClass(
          this.grid,
          x,
          y,
          this.solutionAchieved,
          "sudoku"
      );
    },
    setSelected(cell, x, y) {
      this.$store.commit(this.game + "Store/setSelected", {x, y});
    },
    handleKeyPress(e) {
      e.preventDefault();
      handleKeyPress(e, this.validValues, this.gameStore, this.gameStoreObj);
    },
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyPress);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeyPress);
  },
};
</script>

<style>
table.sudoku {
  border-collapse: collapse;
  border: 2px solid;
  width: 100%;
  line-height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: x-large;
}

@media (min-width: 767.98px) {
  table.sudoku {
    height: 100% !important;
  }
}

td.sudoku {
  border: 1px solid;
  text-align: center;
  width: 10%;
  height: 1.7em;
  min-height: 1.7em;
  font-size: large;
}

table.sudoku tbody tr td:nth-child(3),
table.sudoku tbody tr td:nth-child(6) {
  border-right: 3px solid;
}

table.sudoku tbody tr:nth-child(3),
table.sudoku tbody tr:nth-child(6) {
  border-bottom: 3px solid;
}

td {
  cursor: pointer;
}
</style>
