<template>
  <div>
    <RMPageHeader :key="gameId">
      <template v-slot:header>
        <router-link to="/education" v-if="isEducationMathGame(game)">
          {{ $t("games.education") }}
        </router-link>
        <router-link to="/entertainment" v-else>
          {{ $t("games.entertainment") }}
        </router-link>
        / {{ gameName }}
        <game-instructions v-bind:game="game" v-if="!isEducationMathGame(game)"></game-instructions>
      </template>
    </RMPageHeader>
    <div class="row justify-content-center">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 col-xxxl-6">
        <RMPanel :title="this.gameTitle">
          <template v-slot:panelBody>
            <Games :key="game + gameId" />
          </template>
        </RMPanel>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 col-xxxl-6">
        <div class="row">
          <div class="col">
            <new-game-panel
              :game="game"
              :is-loading="gameInfoLoading"
              :game-info="gameInfo"
            ></new-game-panel>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <continue-game-panel
              :game="game"
              :is-loading="gameInfoLoading"
              :game-info="gameInfo"
            ></continue-game-panel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/config/PageRoutes";
import {mapState} from "vuex";
import ContinueGamePanel from "@/components/games/dispatch/ContinueGamePanel";
import NewGamePanel from "@/components/games/dispatch/NewGamePanel";
import {config} from "@/config/config";
import {gamesService} from "@/_services";
import {handleError} from "@/_helpers/api";
import {snakeCase} from "lodash";
import {isUserLoggedIn} from "@/_helpers";
import RMPageHeader from "@/components/common/RMPageHeader.vue";
import RMPanel from "@/components/common/RMPanel.vue";
import Games from "./Games.vue";
import GameInstructions from "@/components/games/_instructions/GameInstructions.vue";
import {isEducationMathGame} from "@/_helpers/games_helper";

export default {
  name: "GameDispatch",
  data() {
    return {
      gameInfoLoading: false,
      gameInfo: {},
    };
  },
  components: {
    RMPageHeader,
    NewGamePanel,
    ContinueGamePanel,
    RMPanel,
    Games,
    GameInstructions,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    isUserLoggedIn() {
      return isUserLoggedIn();
    },
    game() {
      return this.$route.params.game;
    },
    gameId() {
      return this.$route.params.id;
    },
    gameName() {
      return this.$t("games.games." + this.game + ".name");
    },
    gameTitle() {
      if (!this.gameId) {
        return this.$t('games.common.dailyChallenge');
      }
      return this.$t('games.common.challenge');
    }
  },
  methods: {
    isEducationMathGame,
    initGame() {
      this.gameInfo = {};
      this.gameInfoLoading = true;

      gamesService
        .get_info(this.game)
        .then((data) => {
          let snakeCaseGame = snakeCase(this.game);
          this.gameInfo = data[snakeCaseGame];
          this.gameInfoLoading = false;
        })
        .catch((error) => {
          this.gameInfoLoading = true;
          handleError(error, router);
        });
    },
  },
  mounted() {
    const validGames = Object.keys(config.games.props);

    if (!validGames.includes(this.$route.params.game)) {
      this.$router.push("/");
    }

    this.initGame();
  },
  watch: {
    game() {
      this.initGame();
    },
    gameId() {
      this.initGame();
    },
  },
};
</script>

<style scoped></style>
