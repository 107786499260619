<template>
  <div class="text-center" :key="gameId">
    <caesar-cypher-grid
        v-if="game === 'caesarCypher'"
        :key="period"
        :showed-actions="showedActions"
        :period="period"
        :game-id="gameId"
    ></caesar-cypher-grid>
    <minesweeper-grid
        v-else-if="game === 'minesweeper'"
        key="minesweeper"
        :showed-actions="showedActions"
        :period="period"
        :game-id="gameId"
    ></minesweeper-grid>
    <math-crossword-grid
        v-else-if="game === 'mathCrossword'"
        key="mathCrossword"
        :showed-actions="showedActions"
        :period="period"
        :game-id="gameId"
    ></math-crossword-grid>
    <simple-maze-grid
        v-else-if="game === 'simpleMaze'"
        key="simpleMaze"
        :showed-actions="showedActions"
        :period="period"
        :game-id="gameId"
    ></simple-maze-grid>
    <sudoku9x9
        v-else-if="game === 'sudoku'"
        key="sudoku"
        :showed-actions="showedActions"
        :period="period"
        :game-id="gameId"
    ></sudoku9x9>
    <kakuro-grid
        v-else-if="game === 'kakuro'"
        key="kakuro"
        :showed-actions="showedActions"
        :period="period"
        :game-id="gameId"
    ></kakuro-grid>
    <word-search-grid
        v-else-if="game === 'wordSearch'"
        key="wordSearch"
        :showed-actions="showedActions"
        :period="period"
        :game-id="gameId"
    ></word-search-grid>
    <memory-grid
        v-else-if="game === 'memory'"
        key="memory"
        :showed-actions="showedActions"
        :period="period"
        :game-id="gameId"
    ></memory-grid>
    <simple-math-grid
        v-else-if="game === 'simpleMath'"
        key="simpleMath"
        :showed-actions="showedActions"
        :period="period"
        :game-id="gameId"
    ></simple-math-grid>
    <meme-grid
        v-else-if="game === 'meme'"
        key="meme"
        :showed-actions="showedActions"
        :period="period"
        :game-id="gameId"
    ></meme-grid>
    <crossword-grid
        v-else-if="game === 'crossword'"
        key="crossword"
        :showed-actions="showedActions"
        :period="period"
        :game-id="gameId"
    ></crossword-grid>
    <math-grid
        v-else-if="isEducationMathGame(game)"
        :key="game"
        :showed-actions="showedActions"
        :period="period"
        :game="game"
        :game-id="gameId"
    ></math-grid>
  </div>
</template>

<script>
import {config} from "@/config/config";

import CaesarCypherGrid from "@/pages/games/CaesarCypherGrid";
import CrosswordGrid from "@/pages/games/CrosswordGrid";
import MathCrosswordGrid from "@/pages/games/MathCrosswordGrid";
import MinesweeperGrid from "@/pages/games/MinesweeperGrid";
import SimpleMazeGrid from "@/pages/games/SimpleMazeGrid";
import Sudoku9x9 from "@/pages/games/Sudoku_9x9";
import WordSearchGrid from "@/pages/games/WordSearchGrid";
import MemoryGrid from "@/pages/games/MemoryGrid";
import SimpleMathGrid from "@/pages/games/SimpleMathGrid";
import KakuroGrid from "@/pages/games/KakuroGrid";
import MemeGrid from "@/pages/games/MemeGrid";
import MathGrid from "@/pages/games/education/MathGrid.vue";
import {isEducationMathGame} from "@/_helpers/games_helper";

export default {
  name: "Games",
  methods: {isEducationMathGame},
  components: {
    MathGrid,
    MemoryGrid,
    CrosswordGrid,
    KakuroGrid,
    CaesarCypherGrid,
    MathCrosswordGrid,
    MinesweeperGrid,
    SimpleMazeGrid,
    Sudoku9x9,
    WordSearchGrid,
    SimpleMathGrid,
    MemeGrid
  },
  computed: {
    game() {
      return this.$route.params.game;
    },
    gameId() {
      return this.$route.params.id;
    },
    showedActions() {
      if (!this.gameId) {
        return ["resetGame"];
      }
      return [];
    },
    period() {
      if (!this.gameId) {
        return "daily";
      }
      return "";
    },
  },
  created() {
    const validGames = Object.keys(config.games.props);

    if (
        !validGames.includes(this.$route.params.game)
    ) {
      this.$router.push("/");
    }

    if (isEducationMathGame(this.game)) {
      this.$store.commit("mathStore/initGrid", {
        game: this.game,
        period: this.period,
        gameId: this.gameId,
      });
    } else {
      this.$store.commit(this.game + "Store/initGrid", {
        period: this.period,
        gridId: this.gameId,
      });
    }
  },
};
</script>
