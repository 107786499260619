<template>
  <span>
    <i
        class="fa fa-question-circle game-instructions"
        style="cursor: pointer"
        v-b-modal.modal-instructions
    ></i>
    <b-modal
        id="modal-instructions"
        :title=instructionsStr
        ok-only
        :ok-title=closeButtonStr
    >
      <caesar-cypher-instructions
          v-if="game === 'caesarCypher'"
      ></caesar-cypher-instructions>
      <minesweeper-instructions
          v-else-if="game === 'minesweeper'"
      ></minesweeper-instructions>
      <sudoku-instructions v-else-if="game === 'sudoku'"></sudoku-instructions>
      <word-search-instructions
          v-else-if="game === 'wordSearch'"
      ></word-search-instructions>
      <simple-maze-instructions
          v-else-if="game === 'simpleMaze'"
      ></simple-maze-instructions>
      <math-crossword-instructions
          v-else-if="game === 'mathCrossword'"
      ></math-crossword-instructions>
      <memory-instructions v-else-if="game === 'memory'"></memory-instructions>
      <simple-math-instructions
          v-else-if="game === 'simpleMath'"
      ></simple-math-instructions>
      <meme-instructions
          v-else-if="game === 'meme'"
      ></meme-instructions>
    </b-modal>
  </span>
</template>

<script>
import CaesarCypherInstructions from "@/components/games/_instructions/CaesarCypherInstructions";
import MinesweeperInstructions from "@/components/games/_instructions/MinesweeperInstructions";
import SudokuInstructions from "@/components/games/_instructions/SudokuInstructions";
import WordSearchInstructions from "@/components/games/_instructions/WordSearchInstructions";
import SimpleMazeInstructions from "@/components/games/_instructions/SimpleMazeInstructions";
import MathCrosswordInstructions from "@/components/games/_instructions/MathCrosswordInstructions";
import MemoryInstructions from "@/components/games/_instructions/MemoryInstructions";
import SimpleMathInstructions from "@/components/games/_instructions/SimpleMathInstructions";
import MemeInstructions from "@/components/games/_instructions/MemeInstructions";

export default {
  name: "GameInstructions",
  components: {
    MathCrosswordInstructions,
    CaesarCypherInstructions,
    MinesweeperInstructions,
    SudokuInstructions,
    WordSearchInstructions,
    SimpleMazeInstructions,
    MemoryInstructions,
    SimpleMathInstructions,
    MemeInstructions
  },
  props: {
    game: {
      type: String,
      required: true,
    },
  },
  computed: {
    instructionsStr() {
      return this.$t("games.instructions.title");
    },
    closeButtonStr() {
      return this.$t("games.instructions.closeButton");
    }
  }
};
</script>

<style>
.game-instructions {
  margin-left: 0.3em;
}
</style>
