<template>
    <div class="row">
        <label class="col-md-3 col-form-label" for="mc-content">
            {{ $t(checkboxData.label) }}
        </label>
        <div class="col-md-9">
            <div v-for="(val, _) in checkboxData.values">
                <div class="form-check form-check-inline">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        :id="val.id"
                        :value="val.id"
                        :disabled="val.disabled"
                        :checked="val.checked"
                        v-on:click="handleOnClick"
                    />
                    <label class="form-check-label" :for="val.id">{{ getValueString(val) }}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "ConfigCheckbox",
  data() {
    return {
        operations: this.checkboxData.values
            .filter((val) => val.checked)
            .map((val) => val.id)
    }
  },
  props: {
    game: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    checkboxData: {
      type: Object,
      default: () => {}
    },
    defaultConfig: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    operations: function (newValue) {
      this.$emit("configChange", { operations: newValue });
    },
  },
  methods: {
    getValueString(val) {
      if(val.valueI18N) {
        return this.$t("games.games."  + this.game + "." + val.valueI18N);
      }
      return val.value;
    },
    handleOnClick: function (event) {
        const clickedOperation = event.target.id
        var index = this.operations.indexOf(clickedOperation);
        if (index !== -1) {
            this.operations.splice(index, 1);
        }
        else {
            this.operations = [...this.operations, clickedOperation]
        }
        this.$emit("configChange", 
            { 
                "id": this.checkboxData.id, 
                "values": this.checkboxData.values.filter((val) => this.operations.includes(val.id))
            }
        );
    }
  },
  created() {
    // get IDs of default selected checkboxes
    this.$emit("configChange", 
        { 
            "id": this.checkboxData.id, 
            "values": this.checkboxData.values.filter((val) => val.checked)
        }
    );
  },
}
</script>