<template>
  <div v-if="allFilledUp">
    <div v-if="hasWrongValues" class="alert alert-danger p-4">
      {{ $t("games.common.solvedWrongly") }}
    </div>
    <div v-else class="alert alert-success p-4">{{ $t("games.common.solvedCorrectly") }}</div>
  </div>
</template>

<script>

export default {
  name: "GameMessages",
  props: {
    allFilledUp: {
      type: Boolean,
      default: false,
    },
    hasWrongValues: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
