<template>
  <div>
    <GameMessages
      :all-filled-up="allFilledUp"
      :has-wrong-values="hasWrongValues"
    />
    <MathGridContent
      :game="game"
      :showed-actions="showedActions"
    />
  </div>
</template>

<script>
import GameMessages from "@/components/games/GameMessages.vue";
import {mapState} from "vuex";
import MathGridContent from "@/pages/games/education/MathGridContent.vue"; // Import the KaTeX CSS for styling


export default {
  name: "MathGrid",
  components: {MathGridContent, GameMessages },
  props: {
    game: {
      type: String,
      default: ""
    },
    period: {
      type: String,
      default: "",
    },
    gameId: {
      type: String,
      default: "",
    },
    showedActions: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    ...mapState("mathStore", {
      allFilledUp: (state) => state.allFilledUp,
      hasWrongValues: (state) => state.hasWrongValues,
    }),
  },
}
</script>