<template>
  <div>
    <div class="row form-group m-b-10 justify-content-center" v-if="!isUserLoggedIn">
      <div class="alert alert-warning panel-alert m-b-10">{{ $t("games.dispatch.needToBeLoggedIn") }}</div>
    </div>
    <new-game-settings
      :game="game"
      :is-loading="isLoading"
      :game-info="gameInfo"
      :default-config="defaultConfig"
      :assignment-mode="assignmentMode"
      @newPriceChange="onNewPriceChange"
      @configChange="onConfigChange"
    />
    <div v-if="!assignmentMode">
      <hr/>
      <div class="row form-group m-b-10">
        <div class="col justify-content-center" v-if="this.isUserLoggedIn">
          <div class="alert alert-grey panel-alert" v-html="gamePrice"></div>
        </div>
         <div class="col">
          <button
            type="button"
            :class="newGameButtonClass"
            @click="onStartGame"
            :disabled="isCreatingNewGameDisabled"
          >
            {{ $t("games.dispatch.startGameButton") }}
          </button>
         </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {gamesService} from "@/_services";
import {getCarrotsStateString} from "@/_helpers/games_helper";
import {handleError} from "@/_helpers/api";
import router from "@/config/PageRoutes";
import {isUserLoggedIn} from "@/_helpers";
import NewGameSettings from "@/components/games/NewGameSettings.vue";

export default {
  name: "NewGameInfo",
  components: {
    NewGameSettings,
  },
  data() {
    return {
      gameDifficulty: "",
      gameConfig: {},
      hasEnoughCredit: false,
      gameCreating: false,
      regularPrice: null,
      currentPrice: null
    };
  },
  props: {
    game: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    gameInfo: {
      type: Object,
      default: () => {},
    },
    credit: {
      type: Number,
      default: 0.0,
    },
    assignmentMode: {
      type: Boolean,
      default: false,
    },
    defaultConfig: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    gamePrice() {
      return getCarrotsStateString(this.credit, this.currentPrice, this.regularPrice, this);
    },
    isUserLoggedIn() {
      return isUserLoggedIn();
    },
    isCreatingNewGameDisabled() {
      return this.isLoading || !this.isUserLoggedIn || !this.hasEnoughCredit || this.gameCreating;
    },
    newGameButtonClass() {
      let baseClass = "btn btn-primary w-100";
      if (this.isCreatingNewGameDisabled) {
        baseClass = baseClass + " rm-cursor-default";
      }
      return baseClass;
    },
  },
  methods: {
    onConfigChange(value) {
      this.gameConfig = value;
      this.$emit("onConfigChange", {game: this.game, config: this.gameConfig})
    },
    onNewPriceChange(value) {
      this.hasEnoughCredit = true;
      this.currentPrice = value.current;
      this.regularPrice = value.regular;

      if (this.credit < this.currentPrice) {
        this.hasEnoughCredit = false;
      }
    },
    onPriceChange(value) {
      this.hasEnoughCredit = true;

      this.regularPrice = value.prices.regular;
      this.currentPrice = value.prices.current;
      this.gameDifficulty = value.id;

      if (this.credit < this.currentPrice) {
        this.hasEnoughCredit = false;
      }
    },
    onStartGame() {
      this.gameCreating = true;

      let gameData = {
        difficulty: this.gameDifficulty,
      };

      if (this.gameConfig) {
        gameData.config = this.gameConfig;
      }

      gamesService
        .create_game(this.game, gameData)
        .then((data) => {
          this.gameCreating = false;

          this.$store.commit("account/updateCredit", data.credit_amount);
          router.push(`/games/${this.game}/${data.game_id}`);
        })
        .catch((error) => handleError(error, router, this));

      this.gameCreating = false;
    },
    onGetCarrots() {
      router.push(`/credit-buy`);
    },
    onLoginClick() {
      router.push(`/login`);
    },
  },
};
</script>

<style scoped></style>
