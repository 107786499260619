<template>
  <RMPanel v-bind:title="this.$t('games.dispatch.continueGame')">
    <template v-slot:panelBody>
      <div class="row form-group m-b-10">
        <label class="col-md-3 col-form-label">{{ $t("games.dispatch.chooseGame") }}</label>
        <div class="col-md-9">
          <select
              v-if="hasRecentGames"
              v-model="selectedGame"
              class="form-control"
              style="cursor: pointer;"
              id="recent-game"
              :disabled="isLoading"
          >
            <option
                v-for="(opt, idx) in gameInfo.recent"
                :key="idx"
                :value="opt.id"
            >
              {{ getRecentName(opt) }}
            </option>
          </select>
          <select v-else class="form-control" :disabled="true">
            <option>{{ $t("games.dispatch.noUnsolvedGame") }}</option>
          </select>
        </div>
      </div>
      <hr/>
      <div class="row form-group m-b-10">
        <div class="col-md-3"></div>
        <button
            type="button"
            class="btn btn-primary col-md-6 m-2"
            @click="onContinueGame"
            :disabled="isLoading || !hasRecentGames"
        >
          {{ $t("games.dispatch.continueGameButton") }}
        </button>
        <div class="col-md-3"></div>
      </div>
    </template>
  </RMPanel>
</template>

<script>
import router from "@/config/PageRoutes";
import RMPanel from "@/components/common/RMPanel.vue";

export default {
  name: "ContinueGamePanel",
  components: {RMPanel},
  data() {
    return {
      selectedGame: "",
    };
  },
  props: {
    game: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    gameInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    hasRecentGames() {
      return "recent" in this.gameInfo && this.gameInfo.recent.length > 0;
    },
  },
  methods: {
    getRecentName(opt) {
      const userLocale = navigator.language || 'en-US';
      const dateString = new Date(opt.start_date).toLocaleString(userLocale);
      return this.$t("games.common.started") + dateString;
    },
    onSelectedGameChange(value) {
      this.selectedGame = value;
    },
    onContinueGame() {
      router.push(`/games/${this.game}/${this.selectedGame}`);
    },
  },
  watch: {
    selectedGame: function (newSelectedGame) {
      // Triggered when user changes difficulty
      this.selectedGame = newSelectedGame;
      this.onSelectedGameChange(newSelectedGame);
    },
    gameInfo: function (newInfo) {
      // Triggered on initial load of the page
      if ("recent" in newInfo && newInfo.recent.length > 0) {
        this.selectedGame = newInfo.recent[0].id;
        this.onSelectedGameChange(newInfo.recent[0].id);
      }
    },
  },
};
</script>

<style scoped></style>
