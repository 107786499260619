<template>
  <div>
    <GameMessages
      :all-filled-up="allFilledUp"
      :has-wrong-values="hasWrongValues"
    />
    <table class="rm-table-crossword">
      <tbody>
      <tr v-for="(row, idx) in grid" :key="idx">
        <crossword-cell
          v-for="(cell, idy) in row"
          :key="idy"
          :row-index="idx"
          :col-index="idy"
          :cell="cell"
          :game-store="gameStore"
        ></crossword-cell>
      </tr>
      </tbody>
    </table>
    <TableValues
      :game="game"
      :game-store-obj="this.$store"
      :valid-values="validValues"
      :num-values-per-row="11"
    ></TableValues>
    <h5>{{ $t("games.dispatch.horizontally") }}</h5>
    <table class="rm-table-hints">
      <tbody>
      <tr v-for="(row, idx) in hintsE" :key="idx">
        <td
          v-for="(hint, idy) in row"
          :key="idy"
          :class="getHintClass(hint)"
        >
          {{ hint.v }}
        </td>
      </tr>
      </tbody>
    </table>
    <h5>{{ $t("games.dispatch.vertically") }}</h5>
    <table class="rm-table-hints">
      <tbody>
      <tr v-for="(row, idx) in hintsS" :key="idx">
        <td
            v-for="(hint, idy) in row"
            :key="idy"
            :class="getHintClass(hint)"
        >
          {{ hint.v }}
        </td>
      </tr>
      </tbody>
    </table>
    <GameActions
        v-bind:game="game"
        v-bind:showed-actions="showedActions"></GameActions>
  </div>
</template>

<script>
import {mapState} from "vuex";
import CrosswordCell from "@/components/games/crossword/CrosswordCell";
import GameMessages from "@/components/games/GameMessages.vue";
import {getHintClass, handleKeyPress} from "@/config/stores/common";
import GameActions from "@/components/games/GameActions.vue";
import TableValues from "@/components/games/TableValues.vue";

export default {
  name: "MathCrosswordGrid",
  props: {
    game: {
      type: String,
      default: "crossword"
    },
    gameStore: {
      type: String,
      default: "crosswordStore"
    },
    period: {
      type: String,
      default: "",
    },
    gameId: {
      type: String,
      default: "",
    },
    showedActions: {
      type: Array,
      default: () => [],
    }
  },
  components: {
    TableValues,
    GameActions,
    CrosswordCell,
    GameMessages,
  },
  computed: {
    ...mapState("crosswordStore", {
      completed: (state) => state.completed,
      allFilledUp: (state) => state.allFilledUp,
      hasWrongValues: (state) => state.hasWrongValues,
      grid: (state) => state.grid,
      hintsE: (state) => state.hintsE,
      hintsS: (state) => state.hintsS,
    }),
    validValues() {
      return ["A", "B", "C", "Č", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "R", "S", ""];
    },
    ...mapState({
      account: (state) => state.account,
    }),
    gameStoreObj() {
      return this.$store
    },
  },
  methods: {
    getHintClass,
    handleKeyPress(e) {
      e.preventDefault();
      handleKeyPress(e, this.validValues, this.gameStore, this.gameStoreObj)
    },
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyPress);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleKeyPress);
  },
};
</script>

<style lang="scss" scoped></style>
